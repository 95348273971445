<template>
  <ion-page id="wallet-page">
    <ion-content :fullscreen="true">
      <topbar title="Wallet" :showClose="true" :noboder="false" :backevent="backEvent"></topbar>
      <div class="content" id="walletContent">
        <div
          class="card-box"
          v-for="(item, index) in cardData"
          :key="'card' + index"
          style="overflow: auto; height: 100%"
          :style="`top:${item.positionTop}px;z-index:${item.zIndex + 200}`"
          @click="showCard(item.id)"
        >
          <div class="card member-card" :style="windowHeight < 670 ? 'width: 92%' : ''" v-if="item.type === 0">
            <img src="../assets/card-ritchies@3x.png" />
          </div>
          <div class="card" :class="{ 'dark-shadow': item.zIndex != 1 }" v-else>
            <img class="card-img" v-lazy="item.img" :key="item.img" />
            <div class="card-content">
              <p class="font-18 font-b main-color">{{ item.name }}</p>
              <p class="font-14 label-color font-m">Valid Till {{ item.date }}</p>
            </div>
          </div>
          <div class="code-box" v-if="item.zIndex == 0">
            <vue-barcode :value="item.qrcode"></vue-barcode>
          </div>
          <div style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap; padding: 20px">
            <img v-show="apple" style="margin-bottom: 10px" @click="addAppCodeEvent" src="@/assets/AWallet.png" />
            <img v-show="google" style="height: 64px; margin-bottom: 10px" @click="addGoogleEvent" src="@/assets/GPay.svg" />
            <p style="width: 100%; font-size: 12px; text-align: center">You only need to do this once</p>
          </div>
          <div class="height50"></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
import VueBarcode from "@chenfengyuan/vue-barcode";
import { ScreenBrightness } from "@capacitor-community/screen-brightness";
export default {
  name: "walletPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, VueBarcode },
  data() {
    return {
      focusCardID: 1,
      type: ["C", "S"],
      cardData: [],
      apple: false,
      google: false,
      appBrigthness: "",
      windowHeight: window.innerHeight,
    };
  },
  methods: {
    backEvent() {
      if (this.$route.query.type == 1) {
        this.$router.replace({
          path: "/main/landing",
          query: {
            isLogin: 1,
          },
        });
      } else if (this.$route.query.type == 2) {
        this.$router.replace({
          path: "/main/landing",
          query: {
            isLogin: 1,
          },
        });
      } else {
        this.$router.go(-1);
      }
    },
    init() {
      this.cardData = [];
      this.cardData = this.$store.state.form.cardData;
      if (this.$route.query.id) {
        this.focusCardID = this.$route.query.id;
      } else {
        this.focusCardID = this.cardData[0].id;
      }

      this.resetAnimate();
    },
    resetAnimate() {
      const contentHeight = window.innerHeight;
      const dataLenth = this.cardData.length;
      let zIndex = 0;
      this.cardData.forEach(item => {
        if (item.id == this.focusCardID) {
          item.positionTop = 124;
          item.zIndex = 0;
        } else {
          zIndex++;
          item.zIndex = zIndex + 10;
          item.positionTop = contentHeight - 60 - (dataLenth - zIndex - 1) * 20;
        }
      });
    },
    showCard(id) {
      const contentHeight = window.innerHeight;
      const dataLenth = this.cardData.length;
      let zIndex = 0;
      this.cardData.forEach(item => {
        if (item.id == id) {
          item.zIndex = 0;
          item.positionTop = 124;
        } else {
          zIndex++;
          item.zIndex = zIndex + 10;
          item.positionTop = contentHeight - 60 - (dataLenth - zIndex - 1) * 20;
        }
      });
    },
    addCache(type) {
      localStorage.setItem(
        "wallet",
        JSON.stringify({
          type,
          time: new Date(),
        })
      );
    },
    async addGoogleEvent() {
      //   this.addCache("google");
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.passkitGoogle;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }

      let url = this.shareUrl + "googlepay.html?jwt=" + data.data.result + "&code=" + this.cardData[0].qrcode;
      this.openBrowser(url);
      this.sendGAEvent("Click", "Wallet", "Add Google Pay");
    },
    async addAppCodeEvent() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.passkitGetCodeForIos;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.addAppleEvent(data.data.result);
      this.sendGAEvent("Click", "Wallet", "Add Apple Pay");
    },
    addAppleEvent(code) {
      //   this.addCache("apple");
      let url = this.apiDomain + this.apiConfig.passkitIos;
      url += "?code=" + code;
      this.openBrowser(url);
    },
    async setBrightness(no) {
      await ScreenBrightness.setBrightness({ "brightness": no });
    },
    async getBrightness() {
      const obj = await ScreenBrightness.getBrightness();
      // this.toastEvent(JSON.stringify(obj));
      this.appBrigthness = obj.brightness;
    },
  },
  async ionViewWillEnter() {
    // let cache = localStorage.getItem("wallet");
    // if (cache) {
    //   this.addCacheBtn = false;
    // }
    this.getBrightness();
    this.setBrightness(1);
    this.init();
    this.apple = false;
    this.google = false;
    if (this.$store.state.deviceType == 1) {
      this.apple = true;
    } else if (this.$store.state.deviceType == 2) {
      this.google = true;
    }
    if (this.$route.query.type) {
      this.$store.state.exitApp = true;
    } else {
      this.$store.state.exitApp = false;
    }
    this.sendGAPageView("Wallet");
  },
  ionViewWillLeave() {
    this.setBrightness(this.appBrigthness);
  },
};
</script>
